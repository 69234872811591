import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import User from "../../../images/DummyUser.png";

function PatientCard({
  patientName,
  patientImage,
  patientSex,
  patientId,
  gender,
  appointmentStatus,
  lastAppointmentDate,
}) {
  const navigate = useHistory();
  const [showResponseModal, setShowResponseModal] = useState(false);

  function goToDetails() {
    navigate.push(`/patients/${patientId}`);
  }
  return (
    <Card
      className="appointment-card row cursor-pointer animate__animated animate__fadeIn"
      onClick={goToDetails}
    >
      <div className="col-3">
        <label htmlFor="" className="appointment-card_label">
          Patient
        </label>
        <div className="d-flex align-items-center">
          <img
            src={
              patientImage
                ? process.env.REACT_APP_BUCKET_BASE_URL + patientImage
                : User
            }
            alt=""
            className="appointment-card_img"
          />
          <span className="appointment-card_field">{patientName}</span>
        </div>
      </div>

      <div className="col-3">
        <label htmlFor="" className="appointment-card_label">
          Last appointment date
        </label>
        <div className="d-flex align-items-center">
          <span className="appointment-card_field">
            {/* {moment(timeSlot).format("DD, MMM yyyy")} */}
            22, Apr 2024
          </span>
        </div>
      </div>

      <div className="col-2">
        <label htmlFor="" className="appointment-card_label">
          Gender
        </label>
        <div className="d-flex align-items-center">
          <span className="appointment-card_field">
            {/* {moment(timeSlot).format("LT")} */}
            {gender}
          </span>
        </div>
      </div>
      <div className="col-2">
        <label htmlFor="" className="appointment-card_label">
          Status
        </label>
        <div className="d-flex align-items-center">
          <span className="appointment-card_field border p-1 px-2 rounded">
            Ongoing
          </span>
        </div>
      </div>
      {/* {appointmentType == "upcoming" && <div className="col-2">
      <label htmlFor="" className="appointment-card_label">
        Action
      </label>
      <div className="d-flex align-items-center">
        <button
          className="btn btn-primary p-2 px-3 fs-12"
          onClick={(e) => {
            e.stopPropagation();
            setShowResponseModal(true);
          }}
        >
          Respond
        </button>
      </div>
    </div>} */}
    </Card>
  );
}

export default PatientCard;
