/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import icon1 from "../../../images/icon1.png";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { IoCalendarOutline } from "react-icons/io5";
import { MdOutlineDashboard } from "react-icons/md";
import { PiHospitalLight } from "react-icons/pi";


const iconColor = "#0cc0de"

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let dashBoard = [
        "",]

    let appointments = ["appointments"];

    let chat = ["chat"];

    let patients = ["patients"];

    

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className=" ai-icon" to="/">
              <MdOutlineDashboard size={'30px'} color={iconColor}/>
                {/* <img src={dashbordIcon} /> */}
                <span className="nav-text pl-4">My Dashboard</span>
              </Link>
            </li>

            <li className={`${appointments.includes(path) ? "mm-active" : ""}`}>
              <Link to="/appointments" className="ai-icon">
              <IoCalendarOutline size={"30px"} color={iconColor}/>
                <span className="nav-text pl-4">Appointments</span>
              </Link>
            </li>

            <li className={`${chat.includes(path) ? "mm-active" : ""}`}>
              <Link to="/chat" className="ai-icon">
                <IoChatboxEllipsesOutline size={"30px"} color={iconColor}/>
                <span className="nav-text pl-4">Chat</span>
              </Link>
            </li>
            <li className={`${patients.includes(path) ? "mm-active" : ""}`}>
              <Link to="/patients" className="ai-icon">
                <PiHospitalLight size={"30px"} color={iconColor}/>
                 <span className="nav-text pl-4">Patients</span>
              </Link>
            </li>

            {/* <li className={`${artical.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#">
                {/* <i className="flaticon-381-television"></i> *
                <img src={articalIcon} ></img>
                <span className="nav-text pl-4">CMS</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${path === "article-management" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/article-management"
                  >
                    Article Management
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "advertise-management" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/advertise-management"
                  >
                    Advertise Management
                  </Link>
                </li>
              </ul>
            </li>
          
            */}
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
