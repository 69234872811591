import React, { useReducer, useState } from "react";
import { Card } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import User from "../../../images/DummyUser.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import moment from "moment/moment";
import { Dropdown } from "react-bootstrap";
import { svg1 } from "../../../utils/content";
import RespondToAppointment from "../../modal/RespondToAppointment";

function AppointmentCard({
  bookingFor,
  method,
  status,
  reasonVisit,
  timeSlot,
  patientName,
  patientImage,
  patientSex,
  patientEmail,
  appointmentId,
  appointmentType,
}) {
  const navigate = useHistory();
  const [showResponseModal, setShowResponseModal] = useState(false);

  function goToDetails() {
    navigate.push(`/appointments/${appointmentId}`);
  }

  return (
    <>
      <RespondToAppointment
        show={showResponseModal}
        onHide={() => setShowResponseModal(false)}
        patientImage={patientImage}
        patientName={patientName}
        patientSex={patientSex}
        timeSlot={timeSlot}
        patientEmail={patientEmail}
        method={method}
        appointmentId={appointmentId}
        status={status}
        type={"listing"}
      />
      <Card
        className="appointment-card row cursor-pointer animate__animated animate__fadeIn"
        onClick={goToDetails}
      >
        <div className="col-3">
          <label htmlFor="" className="appointment-card_label">
            Patient
          </label>
          <div className="d-flex align-items-center">
            <img
              src={
                patientImage
                  ? process.env.REACT_APP_BUCKET_BASE_URL + patientImage
                  : User
              }
              alt=""
              className="appointment-card_img"
            />
            <span className="appointment-card_field">{patientName}</span>
          </div>
        </div>

        <div className="col-3">
          <label htmlFor="" className="appointment-card_label">
            Date
          </label>
          <div className="d-flex align-items-center">
            <span className="appointment-card_field">
              {moment(timeSlot).format("DD, MMM yyyy")}
            </span>
          </div>
        </div>

        <div className="col-2">
          <label htmlFor="" className="appointment-card_label">
            Time
          </label>
          <div className="d-flex align-items-center">
            <span className="appointment-card_field">
              {moment(timeSlot).format("LT")}
            </span>
          </div>
        </div>
        <div className="col-2">
          <label htmlFor="" className="appointment-card_label">
            Status
          </label>
          <div className="d-flex align-items-center">
            <span className="appointment-card_field border p-1 px-2 rounded">
              {status}
            </span>
          </div>
        </div>
        {appointmentType == "upcoming" && (
          <div className="col-2">
            <label htmlFor="" className="appointment-card_label">
              Action
            </label>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-primary p-2 px-3 fs-12"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowResponseModal(true);
                }}
              >
                Respond
              </button>
            </div>
          </div>
        )}
      </Card>
    </>
  );
}

export default AppointmentCard;
