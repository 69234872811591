
import React, {
    useCallback,
    useRef,
    useState,
    useImperativeHandle,
    forwardRef
  } from "react";
  
  function isNumeric(str) {
    if (typeof str === "number") return true;
    if (typeof str !== "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
  }
  
  const ScrollPicker = forwardRef(({ itemHeight = 50, style, dataSource, selectedIndex = 0, onValueChange, renderItem, highlightColor = "#333", highlightBorderWidth = 1, wrapperHeight, wrapperBackground = "#fafafa", ...props }, ref) => {
    const [currentIndex, setCurrentIndex] = useState(selectedIndex);
    const scrollViewRef = useRef(null);
  
    useImperativeHandle(ref, () => ({
      scrollToTargetIndex: (index) => {
        setCurrentIndex(index);
        scrollViewRef.current.scrollTo({
          top: index * itemHeight,
          behavior: "smooth"
        });
        if (onValueChange) {
          onValueChange(dataSource[index], index);
        }
      },
    }));
  
    const effectiveWrapperHeight = wrapperHeight || (itemHeight * 5);
  
    const scrollFix = useCallback((event) => {
      const scrollTop = event.target.scrollTop;
      const calculatedIndex = Math.round(scrollTop / itemHeight);
      if (calculatedIndex !== currentIndex) {
        setCurrentIndex(calculatedIndex);
        if (onValueChange) {
          onValueChange(dataSource[calculatedIndex], calculatedIndex);
        }
      }
    }, [itemHeight, dataSource, onValueChange, currentIndex]);
  
    const handleItemClick = index => {
      ref.current.scrollToTargetIndex(index);
    };
  
    const renderItemWrapper = (data, index) => {
      const isSelected = index === currentIndex;
      return (
        <div key={index} style={{
          height: itemHeight,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          scrollSnapAlign: "center", // Ensures the items snap to the center when scrolling
          cursor: "pointer"
        }} onClick={() => handleItemClick(index)}>
          {renderItem ? renderItem(data, index, isSelected) : <div style={{ color: isSelected ? "#333" : "#999" }}>{data}</div>}
        </div>
      );
    };
  
    return (
      <div style={{
        height: effectiveWrapperHeight,
        background: wrapperBackground,
        overflow: "hidden",
        position: "relative"
      }}>
        <div style={{
          position: "absolute",
          top: (effectiveWrapperHeight - itemHeight) / 2,
          height: itemHeight,
          width: "100%",
          borderTop: `${highlightBorderWidth}px solid ${highlightColor}`,
          borderBottom: `${highlightBorderWidth}px solid ${highlightColor}`,
          pointerEvents: "none" // Allows mouse events to pass through
        }} />
        <div
          ref={scrollViewRef}
          style={{
            height: "100%",
            overflowY: "auto",
            scrollSnapType: "y mandatory"
          }}
          onScroll={scrollFix}
        >
          <div style={{ height: (effectiveWrapperHeight - itemHeight) / 2 }} />
          {dataSource.map(renderItemWrapper)}
          <div style={{ height: (effectiveWrapperHeight - itemHeight) / 2 }} />
        </div>
      </div>
    );
  });
  
  export default ScrollPicker;