import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Tab, Nav, Col, Card } from "react-bootstrap";
import CountUp from "react-countup";
import UpdateDocument from "../../modal/UpdateDocument";

// Images

import { dashboard } from "../../../services/AuthService";

import { getDoctorAppointmentListing } from "../../../services/DashboardAsyncFunctions";

const Home = (props) => {
  const [users, setUsers] = useState("");
  const [programArticle, setProgramArticle] = useState("");
  const [tipsArticle, setTipsArticle] = useState("");
  const [filter, setFilter] = useState("");
  const [documentModal, setDocumentModal] = useState(true);


  
  function getDashboardData() {
    // setLoader(true);
    dashboard(filter)
      .then((response) => {
        setUsers(response.data.data.user);
        setProgramArticle(response.data.data.programArticle);
        setTipsArticle(response.data.data.tipsArticle);
        console.log(response.data.data, " table data ");
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
      });
  }

  useEffect(() => {
    // getDashboardData();
  }, [filter]);

  return (
    <>
    {/* <UpdateDocument show={documentModal} onHide={()=>setDocumentModal(false)}/> */}
      <div className="form-head d-flex mb-3 align-items-start">
        <div className="mr-auto d-none d-lg-block">
          <h2 className="text-black font-w600 mb-0">Dashboard</h2>
        </div>
      </div>
      <div className="row">
        <div className='col-xl-3 col-xxl-4 col-lg-6 col-sm-6'>
          <div className='widget-stat card'>
            <div className='card-body p-4'>
              <div className='media ai-icon'>
                <span className='me-3 bgl-warning text-warning mr-2'>
                  <svg
                    id='icon-orders'
                    xmlns='http://www.w3.org/2000/svg'
                    width='30'
                    height='30'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='feather feather-file-text'
                  >
                    <path d='M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z'></path>
                    <polyline points='14 2 14 8 20 8'></polyline>
                    <line x1='16' y1='13' x2='8' y2='13'></line>
                    <line x1='16' y1='17' x2='8' y2='17'></line>
                    <polyline points='10 9 9 9 8 9'></polyline>
                  </svg>
                </span>
                <div className='media-body'>
                  <p className='mb-1'>Patients</p>
                  <h4 className='mb-0'>2570</h4>
                  {/* <span className='badge badge-warning'>+3.5%</span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-3 col-xxl-4 col-lg-6 col-sm-6'>
          <div className='widget-stat card'>
            <div className='card-body  p-4'>
              <div className='media ai-icon'>
                <span className='me-3 bgl-danger text-danger mr-2'>
                <svg
                    id='icon-customers'
                    xmlns='http://www.w3.org/2000/svg'
                    width='30'
                    height='30'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='feather feather-user'
                  >
                    <path d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2'></path>
                    <circle cx='12' cy='7' r='4'></circle>
                  </svg>
                </span>
                <div className='media-body'>
                  <p className='mb-1'>Revenue</p>
                  <h4 className='mb-0'>364.50K</h4>
                  {/* <span className='badge badge-danger'>-3.5%</span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-3 col-xxl-4 col-lg-6 col-sm-6'>
          <div className='widget-stat card'>
            <div className='card-body p-4'>
              <div className='media ai-icon'>
                <span className='me-3 bgl-success text-success mr-2'>
                <i className='flaticon-381-calendar-1'></i>
                </span>
                <div className='media-body'>
                  <p className='mb-1'>Appointments</p>
                  <h4 className='mb-0'>364.50K</h4>
                  {/* <span className='badge badge-success'>-3.5%</span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
