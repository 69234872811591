import React, { useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import ChatCard from "./ChatCard";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getConversationList } from "../../../services/DashboardAsyncFunctions";
import toast from "react-hot-toast";

function ConversationList() {
  const [isLoading, setIsLoading] = useState(true);
  const [chattList, setChatList] = useState(null);
  let totalItems = 0;

  async function getUserChatList() {
    setIsLoading(true);
    try {
      const response = await getConversationList();
      console.log(response);
      setChatList(response.data.data.conversations);
      totalItems = response.data.data.total;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getUserChatList();
  }, []);
  return (
    <Card className="chat-list shadow-md">
      <PerfectScrollbar>
        { !isLoading && chattList &&
          chattList.map((item) => (
            <ChatCard
              key={item._id}
              updatedAt={item.updatedAt}
              lastMessage={item.message}
              type={item.messageType}
              conversationData={item}
              unreadCount={item?.unReadMessage}
            />
          ))}
          {
            (!chattList || chattList.length == 0) && !isLoading &&
              <h5 className="text-center">No Data</h5>
          }
          {isLoading && <div className="d-flex align-items-center justify-content-center"><Spinner animation="border" variant="info" /> </div>  }
      </PerfectScrollbar>
    </Card>
  );
}

export default ConversationList;
