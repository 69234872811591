import React, { useEffect, useState } from 'react'
import { Spinner, Tab, Tabs } from 'react-bootstrap'
import PatientCard from '../components/cards/PatientCard'
import { getPatients } from '../../services/DashboardAsyncFunctions';
import toast from 'react-hot-toast';

function Patients() {
    const [patients, setPatients] = useState(null);
  const [key, setKey] = useState("patient");
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);



  
  async function getMyPatients() {
    if(!hasMoreData) return;
    setIsLoading(true);
    try {
      let response = await getPatients(
        currentPage,
        limit
      );
      console.log(response.data)

      let updatedList = patients
        ? [...patients, ...response.data.data]
        : [...response.data.data];
        setPatients([...updatedList]);
        setHasMoreData(
        response.data.data?.length < limit ? false : true
      );
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  function loadMore() {
      setCurrentPage((prev) => prev + 1);
  
  }


  useEffect(() => {
    getMyPatients();
  }, [currentPage]);

  return (
    <div>
    <div className="form-head d-flex mb-3 align-items-start">
      <div className="mr-auto d-none d-lg-block">
        <h2 className="text-black font-w600 mb-0">Patients</h2>
      </div>
    </div>
    <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="patient" title="My Patients">
          {patients &&
            patients.map((item) => (
              <PatientCard
                key={item._id}
                patientName={item?.name}
                gender={item?.gender}
                patientEmail={item?.email}
                patientImage={item?.image}
                patientId={item._id}
              />
            ))}
          {patients && patients.length == 0 && (
            <div className="text-center">
              <h3>No Data</h3>
            </div>
          )}
          {isLoading && (
            <div className="d-flex align-items-center justify-content-center mb-3">
              <Spinner animation="border" variant="info" />
            </div>
          )}
          <div className="d-flex align-items-center justify-content-center">
            {hasMoreData && (
              <button
                className="fs-14 p-2 px-3 btn-warning btn m-auto"
                onClick={() => loadMore()}
              >
                Load more
              </button>
            )}
          </div>
        </Tab>
        </Tabs>
  </div>
  )
}

export default Patients