import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { respondToUrgentCareRequest } from "../../services/DashboardAsyncFunctions";
import User from "../../../src/images/DummyUser.png";
import moment from "moment";
import eventBus from "../../services/Eventbus";
import { APPOINTMENTS_EVENTS } from "../../utils/content";


function RespondToAppointment({ show, onHide, patientImage, patientName, patientSex,timeSlot,method, patientEmail, appointmentId, status, type }) {
  const [isRespondingToRequest, setIsRespondingToRequest] = useState(false);
  async function respondToRequest(status) {
    setIsRespondingToRequest(true);
    try {
      let response = await respondToUrgentCareRequest({
        status,
        appointmentId,
      });
      if(response.status === 200){
        toast.success("Your response was updated!");
      }
      if(type == "listing"){
        eventBus.emit(APPOINTMENTS_EVENTS.GET_UPCOMING_APPOINTMENTS);
      }else{
        eventBus.emit(APPOINTMENTS_EVENTS.GET_APPOINTMENT_DETAILS);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);

    } finally {
      setIsRespondingToRequest(false);
    }
  }



  return (
    <>
      <Modal
        className="modal fade"
        centered
        onHide={onHide}
        show={show}
      >
        <div className="animate__animated animate__fadeIn p-2" role="document">
          <Modal.Header>
            <h5 className="">Respond to request</h5>
          </Modal.Header>
          <Modal.Body>
            {type == "listing" && <section className="d-flex align-items-start">
            <img src={patientImage ? process.env.REACT_APP_BUCKET_BASE_URL + patientImage : User} alt="" className="rounded mr-3 object-fit-cover" width={120} height={120} />
            <div className="d-flex flex-column align-items-start w-100">
              <div className="row w-100">
              <div className="col-5">
                <label htmlFor="" className="fs-12 mb-0">Name</label>
                <div className="fs-14 text-blackn font-weight-bold capitalize">{patientName}</div> 
              </div>
              <div className="col-7">
                <label htmlFor="" className="fs-12 mb-0">Sex</label>
                <div className="fs-14 text-blackn font-weight-bold capitalize">{patientSex}</div> 
              </div>
              </div>
              <div className="row w-100">
              <div className="col-5">
                <label htmlFor="" className="fs-12 mb-0">Method</label>
                <div className="fs-14 text-blackn font-weight-bold capitalize">{method}</div> 
              </div>
              <div className="col-7">
                <label htmlFor="" className="fs-12 mb-0">Time slot</label>
                <div className="fs-14 text-blackn font-weight-bold capitalize">{moment(timeSlot).format("DD, MMM yyyy")} <span className="fs-12">({moment(timeSlot).format("LT")})</span></div> 
              </div>

              </div>
              <div>
                <label htmlFor="" className="fs-12 mb-0">Email</label>
                <div className="fs-14 text-blackn font-weight-bold capitalize">{patientEmail}</div> 
              </div>

            </div>
            </section>}
            {type == "details" && <section>
                <h3>Are you available for this consultation?</h3>
            </section>}
             
          </Modal.Body>
          <Modal.Footer className=" d-flex align-items-center">
              {!isRespondingToRequest && <Button
                type="button"
                className=" flex-grow-1 shadow-sm"
                variant="success light"
                disabled={isRespondingToRequest || (status == "Rejected" || status == "Accepted")}
                onClick={() => respondToRequest("Accept")}
              >
                Accept
              </Button>}
              {!isRespondingToRequest && <Button
                type="button"
                onClick={() => respondToRequest("Reject")}
                disabled={isRespondingToRequest || (status == "Rejected" || status == "Accepted")}
                className=" flex-grow-1 shadow-sm"
                variant="danger light"
              >
                <i className="flaticon-delete-1"></i> Reject
              </Button>}
              {isRespondingToRequest && <button
                type="button"
                disabled
                className="btn btn-secondary flex-grow-1"
              >
                Loading...
              </button>}
        
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(RespondToAppointment);
