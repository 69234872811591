import io from 'socket.io-client';
const SOCKET_URL = process.env.REACT_APP_API_BASE_URL;

class WSService {
    //vatiables
socket;
  initializeSocket = async (initialConnect, disconnectCleanup) => {
    const token = localStorage.getItem("_anytime_doctor_token_");
    try {
      this.socket = io(`${SOCKET_URL}?token=${token}`);
      this.socket.on('connect', data => {
        // EventRegister.emit(EVENT_TITLE.SOCKET_CONNECTED);
        console.log('===== socket connected =====');
        initialConnect?.();
      });

      this.socket.on('connection', data => {
        // EventRegister.emit(EVENT_TITLE.SOCKET_CONNECTED);
        console.log('===== socket connected =====');
        initialConnect?.();
      });

      this.socket.on('disconnected', err => {
        console.log('socket disconnected', err);
        disconnectCleanup?.();
      });
    } catch (error) {
      console.log(error, 'hter tereo');
    }
  };
  
  emit(event, data = {}) {
    // alert(SOCKET_EMIT ${event} ${JSON.stringify(data)});
    this.socket?.emit(event, data);
  }

  on(event, cb) {
    this?.socket?.removeListener?.(event);
    this?.socket?.on?.(event, data => {
      cb(data);
    });
  }

  removeListener(listenerName) {
    this.socket.removeListener(listenerName);
  }
}

const socketServices = new WSService();

export default socketServices;