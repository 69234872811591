import React from 'react';
import { Modal } from "react-bootstrap";
import { Dropdown, Card, Table, Badge, Col, Button } from "react-bootstrap";
import { getUserCacheDataFromLocalStorage } from '../../services/AuthService';
import DocumentCard from '../components/cards/DocumentCard';

function UpdateDocument({show, onHide}) {
  const userDetailsFromStorage = getUserCacheDataFromLocalStorage();
  return (
    <Modal className="modal fade" centered onHide={onHide} show={show}>
    <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-center">
           <h3>Update Documents</h3>
            </div>
          </Card.Header>
          <Card.Body>
           <div>
            <input type="file" name="" id="" className='mb-3'/>
            <DocumentCard />
            <DocumentCard />
            <DocumentCard />
               <button className='btn btn-primary' >
                Update
               </button>
           </div>
           {userDetailsFromStorage?.documentStatus == "submitted" && (
                <div
                  role="alert"
                  className="fade fade show alert alert-warning show mt-3"
                >
                  Your documents are under review
                </div>
              )}
          </Card.Body>
        </Card>
      </Col>
    </Modal>
  )
}

export default UpdateDocument;