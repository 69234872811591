import {
  APPOINTMENT_LISTING,
  GET_APPOINTMENT_DETAILS,
  GET_CHAT_MESSAGES,
  GET_CONVERSATION_LIST,
  GET_LAB,
  GET_PATIENTS,
  GET_PATIENT_APPOINTMENTS,
  GET_PHARMACIES,
  GET_SUGGESTIONS,
  RESPOND_TO_REQUEST,
  SEND_PRESCRIPTION,
  SEND_SUGGESTION,
  SEND_TEST,
} from "./AuthApiEndPoints";
import axiosInstance from "./AxiosInstance";

export async function getDoctorAppointmentListing(type, page, limit) {
  return axiosInstance.get(
    APPOINTMENT_LISTING + `?page=${page}&limit=${limit}&type=${type}`
  );
}

export async function respondToUrgentCareRequest(payload) {
  return axiosInstance.put(RESPOND_TO_REQUEST, payload);
}

export async function getAppointmentDetailsById(Id) {
  return axiosInstance.get(GET_APPOINTMENT_DETAILS + `?requestId=${Id}`);
}

export async function getPharmaciesList(appointmentId) {
  return axiosInstance.get(
    GET_PHARMACIES + `?page=0&limit=20&appointmentId=${appointmentId}`
  );
}

export async function sendPrescriptionToUser(payload) {
  return axiosInstance.post(SEND_PRESCRIPTION, payload);
}
export async function sendSuggestion(payload) {
  return axiosInstance.post(SEND_SUGGESTION, payload);
}

export async function getChatMessageList(conversationId) {
  return axiosInstance.get(
    GET_CHAT_MESSAGES + `?page=0&limit=1000&conversationId=${conversationId}`
  );
}

export async function getConversationList() {
  return axiosInstance.get(GET_CONVERSATION_LIST + `?page=0&limit=10`);
}

export async function getPatients(page, limit) {
  return axiosInstance.get(GET_PATIENTS + `?page=${page}&limit=${limit}`);
}

export async function getPatientAppointments(patientId) {
  return axiosInstance.get(
    GET_PATIENT_APPOINTMENTS + `?page=0&limit=10&userId=${patientId}`
  );
}
export async function getMedicineSuggestionList(search) {
  return axiosInstance.get(GET_SUGGESTIONS + `?search=${search}`);
}


export async function getLabList(appointmentId) {
  return axiosInstance.get(
    GET_LAB + `?page=0&limit=20&appointmentId=${appointmentId}`
  );
}

export async function sendTestToUser(payload) {
  return axiosInstance.put(SEND_TEST, payload);
}