import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { respondToUrgentCareRequest } from "../../services/DashboardAsyncFunctions";
import socketServices from "../../services/SocketServices";
import { SOCKET_GLOBAL_EVENTS } from "../../utils/content";
import AlertClip from "../../images/request-alert.mp3";

let timerLimit = 10;

function IncomingUrgentRequest({ show, onHide, showModal, socketConnected }) {
  const [countdown, setCountdown] = useState(timerLimit);
  const [isRespondingToRequest, setIsRespondingToRequest] = useState(false);
  const [appointmentId, setAppointmentId] = useState(null);
  const [isUserPaying, setIsUserPaying] = useState(false);
  const [requestType, setRequestType] = useState("");
  const audio = new Audio(AlertClip);

  const timer = useRef(null);
 
  async function respondToRequest(status) {
    setIsRespondingToRequest(true);
    try {
      let response = await respondToUrgentCareRequest({
        status,
        appointmentId,
      });
      console.log(response);
      setCountdown(timerLimit);
      if (status == "Reject") {
        onHide();
        clearInterval(timer.current);
        toast.success("The request was rejected!");
      }
      if (response.status === 200 && status == "Accept") {
        console.log(timer.current, "asdasdasdsadasd")
        clearInterval(timer.current);
        setCountdown(timerLimit);
        onHide();
        setIsUserPaying(requestType == "urgentCare" ? true : false);
        if(requestType !== "urgentCare") onHide();
        if(requestType !== "urgentCare") {
          window.open(response?.data?.data?.zoomMeetingLink, "_blank");
          onHide();
        }
      }
      socketServices.removeListener(SOCKET_GLOBAL_EVENTS.REQUEST_TIMED_OUT);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsRespondingToRequest(false);
    }
  }




  function removeListeners(){
    socketServices.removeListener(SOCKET_GLOBAL_EVENTS.NO_RESPONSE_FROM_DOCTOR);
    socketServices.removeListener(SOCKET_GLOBAL_EVENTS.RECIEVE_REQUEST);
    socketServices.removeListener(SOCKET_GLOBAL_EVENTS.JOIN_CALL_AFTER_PAYMENT);
    socketServices.removeListener(SOCKET_GLOBAL_EVENTS.REQUEST_TIMED_OUT);

  }

  useEffect(() => {
    if (isUserPaying) {
      timer.current = setInterval(() => {
        console.log("isUserPaying");
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => {
      clearInterval(timer.current);
    };
  }, [isUserPaying]);

  useEffect(() => {
    if (show) {
      audio.play();
      timer.current = setInterval(() => {
        console.log("initial");
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    if (!show) {
      clearInterval(timer.current);
      setCountdown(timerLimit);
      setIsUserPaying(false);
      setIsRespondingToRequest(false);
    }

    return () => {
      clearInterval(timer.current);
      audio.pause();
    };
  }, [show]);

  useEffect(() => {
    if (countdown === 0) {
      onHide();
      setCountdown(timerLimit);
    }
  }, [countdown, onHide]);

  useEffect(() => {
    socketServices.on(SOCKET_GLOBAL_EVENTS.NO_RESPONSE_FROM_DOCTOR, (data) => {
      console.log("no response from docotor")
      onHide();
      toast("You failed to respond to the request");
      setCountdown(timerLimit);
    });
    socketServices.on(SOCKET_GLOBAL_EVENTS.RECIEVE_REQUEST, (data) => {
      //do stuff with data
      console.log(data);
      setRequestType(data?.type);
      setAppointmentId(data?._id);
      showModal();
      socketServices.on(SOCKET_GLOBAL_EVENTS.REQUEST_TIMED_OUT, (data) => {
        onHide();
        toast("Patient couldn't complete payment, request timed out.");
        setCountdown(timerLimit);
        clearInterval(timer.current);
        socketServices.removeListener(SOCKET_GLOBAL_EVENTS.REQUEST_TIMED_OUT);
      });
    });
    socketServices.on(SOCKET_GLOBAL_EVENTS.JOIN_CALL_AFTER_PAYMENT, (data) => {
      onHide();
      clearInterval(timer.current);
      setCountdown(timerLimit);
      console.log(data)
      window.open(data?.data?.url, "_blank");
    });
    return()=>{
      removeListeners();
    }
  }, [socketConnected]);

  return (
    <>
      <Modal
        className="modal fade"
        backdrop="static"
        centered
        onHide={onHide}
        show={show}
      >
        <div className="animate__animated animate__fadeIn p-3" role="document">
          <Modal.Header>
            <h5 className="">{requestType == "urgentCare" ? "Urgent Care" : "Free Consultation"} Request</h5>
          </Modal.Header>
          <Modal.Body>
            {!isUserPaying && (
              <h3 className="texgt-center">
                You have {requestType == "urgentCare" ? "an urgent care" : "a free consultation"} request! Please respond.
              </h3>
            )}
            {isUserPaying &&  (
              <h3 className="texgt-center">
                Please wait while we will connect you to the patient.
              </h3>
            )}
            <h4 className="text-center animate__animated animate__zoomIn animate__infinite text-danger fs-6">
              {countdown} <span className="fs-12">sec</span>
            </h4>
          </Modal.Body>
          <Modal.Footer className=" d-flex align-items-center">
            {!isRespondingToRequest && (
              <button
                type="button"
                className="btn btn-success flex-grow-1"
                disabled={isUserPaying}
                onClick={() => respondToRequest("Accept")}
              >
                Accept
              </button>
            )}
            {!isRespondingToRequest && (
              <button
                type="button"
                onClick={() => respondToRequest("Reject")}
                disabled={isUserPaying}
                className="btn btn-warning bg-danger flex-grow-1"
              >
                <i className="flaticon-delete-1"></i> Reject
              </button>
            )}
            {isRespondingToRequest && (
              <button
                type="button"
                disabled
                className="btn btn-secondary flex-grow-1"
              >
                Loading...
              </button>
            )}
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(IncomingUrgentRequest);
