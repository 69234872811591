import AWS from "aws-sdk";
AWS.config.update({
  // region: process.env.REACT_APP_COGNITO_POOL_REGION,
  region: "ap-southeast-1",
  credentials: new AWS.CognitoIdentityCredentials({
    // IdentityPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
    IdentityPoolId: "ap-southeast-1:3d571fe5-56da-4d53-9e47-386aca10bc42"
  }),
});
export default async function addFileToS3Bucket(files, albumName) {
  console.log(files, "file in test files");
  var file = files;
  var fileName = Math.floor(Math.random() * 10000000) + file.name;
  var albumPhotosKey = albumName + "/";
  var photoKey = albumPhotosKey + fileName;

  var upload = new AWS.S3.ManagedUpload({
    params: {
      // Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Bucket: "demandhealthcare",
      Key: photoKey,
      Body: file,
      ACL: "public-read",
    },
  });

  // var upload = new AWS.S3().upload({
  //     Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
  //     Key: photoKey,
  //     Body: file,
  //     ACL: "public-read",
  //     ContentType: 'image/*'
  // });


  var promise = await upload.promise();
  promise.imageName = fileName;
  return promise;
}