import React, { useEffect, useState } from "react";
import { Card, Col, Modal } from "react-bootstrap";
import PharmacyCard from "../components/cards/PharmacyCard";
import {
    getLabList,
  getMedicineSuggestionList,
  getPharmaciesList,
} from "../../services/DashboardAsyncFunctions";
import toast from "react-hot-toast";
import Swal from "sweetalert2";

function SelectLab({ onHide, show, appointmentId, handleSubmit }) {
  const [pharmacyArray, setPharmacyArray] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedPharmacies, setSelectedPharmacies] = useState(null);
  const [stepCount, setStepCount] = useState(1);
  const [medicineName, setMedicineName] = useState(null);
//   const [instructions, setInstructions] = useState(null);
  const [prescriptionList, setPrescriptionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState('');


  const handleSuggestionClick = (suggestion) => {
    setMedicineName(suggestion);
    // setSelectedSuggestion(suggestion);
    // setSuggestions([]);
  };
  // const handleInputChange = (e) => {
  //   const inputValue = e.target.value;
  //   setMedicineName(inputValue);

  //   setSuggestions(["partner1", "partner2"]);
  // };

  async function submit() {
    if (!selectedPharmacies || selectedPharmacies?.length == 0) {
      const result = await Swal.fire({
        title: "No lab were selected, you want to send Test?",
        text: "This action cannot be undone!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });

      if (!result.isConfirmed) return;
      handleSubmit(prescriptionList, selectedPharmacies);
    } else {
      handleSubmit(prescriptionList, selectedPharmacies);
    }
  }

  function handleSelection(pharmaId) {
    if (selectedPharmacies && selectedPharmacies.includes(pharmaId)) {
      let tempList = selectedPharmacies.filter((item) => item !== pharmaId);
      setSelectedPharmacies([...tempList]);
    } else {
      selectedPharmacies &&
        setSelectedPharmacies((prev) => [...prev, pharmaId]);
      !selectedPharmacies && setSelectedPharmacies([pharmaId]);
    }
  }

  function addPrescriptionToList(e) {
    e.preventDefault();
    if (!medicineName) {
      toast.error("Enter medicine name");
      return;
    }
    //  else if (!instructions) {
    //   toast.error("Enter Dosage instructions");
    //   return;
    // } 
    else {
      if (prescriptionList.length == 0) {
        setPrescriptionList([
          {
            name: medicineName,
            // description: instructions,
          },
        ]);
      } else {
        setPrescriptionList((prev) => [
          ...prev,
          {
            name: medicineName,
            // description: instructions,
          },
        ]);
      }
      setMedicineName("");
    //   setInstructions("");
    }
  }
  function removeMedicineFromList(ItemIndex) {
    const tempList = prescriptionList.filter(
      (item, index) => ItemIndex !== index
    );
    setPrescriptionList([...tempList]);
  }

  useEffect(() => {
    const getLabforAppointment = async () => {
      try {
        const response = await getLabList(appointmentId);
        setPharmacyArray(response.data?.data?.labs);
        // if (response.status == 200) {
        //   toast.success("Success");
        // }
        console.log(response, "lab list response");
      } catch (error) {
        toast.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };
    if (show) {
        getLabforAppointment();
    }
  }, [show]);


  return (
    <Modal
      className="modal fade"
      size="lg"
      centered
      onHide={onHide}
      show={show}
    >
      <Col>
        {stepCount == 1 && (
          <Card>
            <Card.Header className="d-block">
              <div className="d-flex justify-content-between align-items-center">
                <h3>Write Test</h3>
                {/* <button type="button" className="btn-close"  data-dismiss="modal">X</button> */}
              </div>
            </Card.Header>

            <Card.Body>
              <form
                onSubmit={addPrescriptionToList}
                className="d-flex align-items-center"
              >
                <input
                  type="text"
                  placeholder="Test Name"
                  value={medicineName}
                  onChange={(e) => setMedicineName(e.target.value)}
                  className="form-control mr-2"
                  name="medicine"
                />

                {/* <input
                  type="text"
                  placeholder="Dosage Instructions"
                  value={instructions}
                  onChange={(e) => setInstructions(e.target.value)}
                  className="form-control mr-2"
                  name="medicine"
                /> */}

                <button className="btn btn-outline-primary" type="submit">
                  Add
                </button>
              </form>
              {suggestions.length > 0 &&  (
                <div className="suggestions">
                  {suggestions?.map((suggestion, index) => (
                    <div
                      key={index}
                      className="suggestion"
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion}
                    </div>
                  ))}
                </div>
              )}
              <ol className="">
                {prescriptionList.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center justify-content-between mt-3"
                  >
                    <div className="pharma-card_distance">
                      <span className="font-weight-bold">{index + 1}.</span>{" "}
                      {item.name}
                    </div>
                    <div className="pharma-card_distance">
                      {item.description}
                    </div>
                  </div>
                ))}
              </ol>
            </Card.Body>
            <Card.Footer>
              <button
                className="btn btn-primary w-100"
                onClick={() => setStepCount((prev) => prev + 1)}
              >
                Next
              </button>
            </Card.Footer>
          </Card>
        )}

        {stepCount == 2 && (
          <Card>
            <Card.Header className="d-block">
              <div className="d-flex justify-content-between align-items-center">
                <h3>Select Lab</h3>
              </div>
            </Card.Header>

            <Card.Body>
              {!loading &&
                pharmacyArray &&
                pharmacyArray.map((item, index) => (
                  <PharmacyCard
                    key={item._id}
                    handleSelection={handleSelection}
                    pharmaId={item._id}
                    selectedPharmacies={selectedPharmacies}
                    name={item.labName}
                    email={item.email}
                    location={item.address}
                    distance={item.distance}
                    openingHours={item.openingHours}
                    index={index}
                    type="selection"
                  />
                ))}
              {loading && (
                <div className="text-center">
                  Fetching Lab close to the patient...
                </div>
              )}
              {!loading && pharmacyArray && pharmacyArray.length == 0 && (
                <div className="text-center font-weight-bold">
                  No lab Available
                </div>
              )}
            </Card.Body>
            <Card.Footer className="d-flex align-items-center ">
              <button className="btn btn-primary w-100 mr-2" onClick={submit}>
                Send to user
              </button>
              <button
                className="btn btn-outline-primary w-100"
                onClick={() => setStepCount((prev) => prev - 1)}
              >
                Back
              </button>
            </Card.Footer>
          </Card>
        )}
      </Col>
    </Modal>
  );
}

export default SelectLab;
