import React, { useEffect, useLayoutEffect, useState } from "react";
import ConversationList from "../components/chat/ConversationList";
import MessagesList from "../components/chat/MessagesList";
import socketServices from "../../services/SocketServices";
import { delay } from "../../utils/helperFunctions";
import { useSelector } from "react-redux";
import { getChatMessageList } from "../../services/DashboardAsyncFunctions";

function Chat() {
 const [messageList, setMessageList] = useState([]);
 const selectedConversation = useSelector(state=> state.session.selectedConversationDetails);
 const [isChatFetching, setIsChatFetching] = useState(false);

async function initialConnect() {
  setMessageList([]);
    if(selectedConversation){
      setTimeout(() => {
        socketServices.emit("joinConversation", {
          conversationId: selectedConversation?.conversationId,
          userId: "65ddaa3ee21f22008fb49db5",
        });
      },500);
    }
    socketServices.on("receiveMessage", (data) => {
      console.log(data);
      setMessageList(prev => [...prev, data])
    });

    socketServices.on("joinedConversation", (data) => {
      console.log(data);
      getChatMessages(data);
    });

    socketServices.on("leavedConversation", (data) => {
      console.log(data);
    });
  }

function leaveConversation(){
  if(selectedConversation){
    socketServices.emit("leaveConversation", {
      conversationId: selectedConversation?.conversationId,
      userId: "65ddaa3ee21f22008fb49db5"
  })
  }
  socketServices.removeListener("receiveMessage");
  socketServices.removeListener("joinedConversation");
}

async function getChatMessages(conversationId){
  setIsChatFetching(true)
    try{
      const response = await getChatMessageList(conversationId);
      console.log(response);
      setMessageList(response.data?.data?.chat.reverse());
    }catch(error){

    }finally{
      setIsChatFetching(false)
    }
}
  

  useEffect(() => {
    // leaveConversation();
    initialConnect();
    return ()=> leaveConversation()
  }, [selectedConversation]);

  useLayoutEffect(() => {
    document.body.classList.add("no-scroll");
    return () => document.body.classList.remove("no-scroll");
  }, []);
  return (
    <div className="chat-page">
      <ConversationList />
      <MessagesList messageList={messageList} isChatFetching={isChatFetching} conversationSelectedTrue={selectedConversation}/>
    </div>
  );
}

export default Chat;
